// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Highlights from 'components/Contentful/Highlights'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Wärmepumpe und Photovoltaik-Anlage live erleben"
        meta={[
          { name: 'description', content: 'Haben Sie schon einmal live erlebt, wie eine installierte Wärmepumpe oder Photovoltaik-Anlage funktioniert? Wissen Sie wie laut eine WP ist? Was macht eigentlich ein Wechselrichter? Datum reservieren und reinschauen!' },
          { name: 'keywords', content: 'Tag der offenen Wärmepumpe, Tag der offenen Photovoltaik-Anlage, Tag der offenen Türe' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='11md4pPWTeO3uW2TQwCtOE-361tCPhSboT33glHJvpKqR'
          id='tage-der-offenen-waermepumpe-in-ihrer-region'
        >
          <Highlights
            {...{
    "type": "highlights",
    "id": "361tCPhSboT33glHJvpKqR",
    "updatedAt": "2024-03-18T10:43:11.443Z",
    "title": "Tage der offenen Wärmepumpe & Photovoltaik-Anlage in Ihrer Region",
    "description": "<p>Haben Sie schon einmal live erlebt, wie eine installierte Wärmepumpe funktioniert? Wissen Sie wie laut eine Wärmepumpe ist? Was macht eigentlich ein Wechselrichter bei einer Photovoltaik-Anlage?\nErleben Sie die Wärmepumpe &#x26; Photovoltaik-Anlage mit Ihrer ganzheitlichen Installation direkt vor Ort und lassen Sie sich von Experten beraten.</p>\n",
    "highlights": [
        {
            "type": "highlight",
            "id": "2CenFNbpwBQKpHHoNj8Ma2",
            "updatedAt": "2024-03-18T10:43:06.526Z",
            "title": "Derzeit ist kein Anlass geplant",
            "description": "Hier erfahren Sie, wann wir den nächsten Event durchführen werden. Selbstverständlich dürfen Sie bei Fragen jederzeit mit unseren Experten kontakt aufnehmen. Gerne führen wir auch mit Ihnen persönlich Besuch bei Referenzobjekten von uns durch.",
            "link": {
                "type": "link",
                "id": "4qFwguL0kN6f6OGRWTjLbm",
                "updatedAt": "2021-02-11T13:33:08.645Z",
                "label": "Referenzen",
                "page": {
                    "slug": "referenzen"
                }
            }
        }
    ],
    "anchorName": "tage-der-offenen-waermepumpe-in-ihrer-region"
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
